import {useState} from 'react';
import { ICategory, IRegister } from './Insights';
import Aspect from './Aspect';

import SVGs from '../../assets';

const AngleBracket = SVGs.AngleBracket;

// --elevation-light-blue: #80c1ff;
// --elevation-red: #AA2b3e;
// --elevation-light-grey: #dbdcdd;


const Register = ({register}:{register:IRegister|ICategory}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    let k = register.label;
    // @ts-ignore
    const { key } = register;
    if(key) k = key;
    // @ts-ignore
    const {color='blue'} = register;
    const colorString = color=== 'red' ? 'var(--elevation-red)' : 'var(--elevation-green)';
    return (
        <div className='registerBox' key={k}>
            <div onClick={toggle} className='row toggleBox'>
                <h3>{register.label}</h3>
                <div className="row center bracketmover">
                    <h4 className={`${isOpen?'transparent':'opaque'}`} style={{color:colorString}}>{ // @ts-ignore
                        register.data[0].value}</h4>
                    <AngleBracket fill={colorString} stroke={colorString} isOpen={isOpen} />
                </div>
            </div>
            <div className={`accordianContent ${isOpen?'tall':'short'}`}>
                {/* <img src={AngleBracket} alt='arrow' className={`arrow ${isOpen?'open':'closed'}`} /> */}
                { register.data && register.data.map((aspect) => {
                    return <Aspect 
                                className='aspectArrow'
                                // @ts-ignore 
                                key={aspect.key}
                                // @ts-ignore 
                                aspect={aspect} />
                })}
            </div>
        </div>)
};

export default Register;
