import { IAspectValue } from "./Insights";

const Aspect = ({aspect}:{aspect:IAspectValue}) => {
    if(!aspect) return null;
    const { key, label, value, color } = aspect;
    return (
        <div className={`accordian-aspect-row ${color}`} key={key}>
            <div className={`aspect-label asp-${key} ${color}`}>{label}</div>
            <div className={`aspect-value asp-${key} asp-${value} ${color}`}>{value}</div>
        </div>)
};
export default Aspect;