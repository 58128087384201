import Category from "./Category";
import assets from "../../assets";

interface IInsight {
  timestamp: string;
  data: ICategory[];
}

interface ICategory {
  key: string;
  label: string;
  description: string;
  data: IRegister[]|IAspectValue[];
}

interface IRegister {
  label: string;
  value: string;
  color: string|null;
  data: IAspectValue[];
}

interface IAspectValue {
  key: string;
  label: string;
  value: string;
  color: string;
}

// const example:IInsight = {
// "timestamp": "2024-01-26T12:00:00.000Z",
// "data": [
//   {
//     "key": "hvac",
//     "label": "HEATING & COOLING STATUS",
//     "description": "Check the status of your heating and cooling equipment to ensure they're functioning properly and efficiently, and not leading to unnecessary expenses.",
//     "data": [
//       {
//         "label": "A/C #1",
//         "value": "Service Needed",
//         "color": "red",
//         "data": [
//           {
//             "key": "filter_status",
//             "label": "Filter",
//             "value": "Service Needed",
//             "color": "red"
//           },
//           {
//             "key": "capacitor_status",
//             "label": "Capacitor",
//             "value": "Good",
//             "color": "green"
//           },
//           {
//             "key": "performance_estimate",
//             "label": "Performance",
//             "value": "Good",
//             "color": "green"
//           }
//         ]
//       },
//       {
//         "label": "A/C #2",
//         "value": "Good",
//         "color": null,
//         "data": [
//           {
//             "key": "filter_status",
//             "label": "Filter",
//             "value": "Good",
//             "color": "green"
//           },
//           {
//             "key": "capacitor_status",
//             "label": "Capacitor",
//             "value": "Good",
//             "color": "green"
//           },
//           {
//             "key": "performance_estimate",
//             "label": "Performance",
//             "value": "Good",
//             "color": "green"
//           }
//         ]
//       },
//       {
//         "label": "INSULATION",
//         "value": "Good",
//         "color": null,
//         "data": [
//           {
//             "key": "attic_insulation",
//             "label": "Attic Insulation",
//             "value": "Good",
//             "color": "green"
//           }
//         ]
//       }
//     ]
//   }
// ]
// }


const Insights = ({data}:{data:IInsight}) => {
  const {filterPhoto, insulationPhoto} = assets;
    if(!data || !data.data) {
        return null;
    }
  const accordians = data.data.map((category:ICategory) => {
    return (
      <div className='accordian' key={category.key}>
        <div className='accordianCategory'>
          <h1 className={`insightsHeader`}>{category.label}</h1>
          <p className={`insightsCopy`}>{category.description}</p>
          <Category category={category} />
        </div>
      </div>
    );
  });


  return (
    <div className={`insightsPage`}>
      <div className="insightsDasboard">
        {accordians}
      </div>
      <div className="ctaOne ctaBox">
        <h4>Order A/C Filters</h4>
        <p>Today may be the right time for a new A/C filter. Shop now and get your new filter delivered to your home.</p>
        <img src={filterPhoto} alt="A/C Filter" className="ctaImage" />
        <button className="ctaButton">Shop Now</button>
      </div>
      <div className="ctaTwo ctaBox">
        <h4>SCHEDULE AN ENERGY AUDIT</h4>
        <p>Today may be the right time for a new A/C filter. Shop now and get your new filter delivered to your home.</p>
        <img src={insulationPhoto} alt="A/C Filter" className="ctaImage" />
        <button className="ctaButton">Call Now (844) 629-2872</button>
      </div>
    </div>
  );
};

export default Insights;
export type { IInsight, ICategory, IRegister, IAspectValue };
